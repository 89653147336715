<template>
  <div class="page">

    <div v-for="(item,index) in echartsList" :id="'echart_'+item.id" class="echart">

    </div>

  </div>
</template>

<script>
import {echarts} from '@/api/public.js'

export default {
  name: "echarts",
  data() {
    return {
      echartsList: [],
    }
  },
  created() {

  },
  mounted() {
    this.initCaption();
  },
  beforeUpdate() {

  },
  methods: {

    // 初始化数据
    initCaption() {
      // 数据格式
      echarts().then(res => {
        this.echartsList = res;

        //console.log("ceshi:",res)

        for(let option in this.echartsList){
          let instance = this.echartsList[option];
          this.$nextTick(()=>{
            let myChart = this.$echarts.init(document.getElementById("echart_"+instance["id"]));
            myChart.setOption(instance);
          });
        }
      });
    },

  },
}
</script>

<style lang="scss">

.echart {
  float:left;
  width: 33%;
  height: 300px;
}

@media (max-width: 768px) {

  .echart {
    width: 100%;
  }

}
</style>